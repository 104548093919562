let url;
let urlb;
let socketurl;

//if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  url = "https://api.ahm.kelola.biz/api";
  urlb = "https://api.ahm.kelola.biz";
  socketurl = "https://api.ahm.kelola.biz";
//} else {
  url = "https://api.ahm.kelola.biz/api";
  urlb = "https://api.ahm.kelola.biz";
  socketurl = "https://api.ahm.kelola.biz";
//}

const appName = "Dharma";
const customerStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: "transparent",
    boxShadow: isFocused
      ? "0 0.313rem 0.719rem rgba(0,123,255,.1), 0 0.156rem 0.1>"
      : "",
  }),
};
export { url, appName, customerStyles, urlb, socketurl };
